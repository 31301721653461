import { DrupalNode } from "next-drupal"
import config from "../../site.config";
import {useTranslation} from "next-i18next";
import parse from 'html-react-parser';
import {Webform} from "../webform/webform";

export interface NodeMicrositeProps {
    node: DrupalNode,
    additionalContent: {
        headers?: string|string[],
        micrositeHTML: DrupalNode[]
    }
}

export function NodeMicrosite({ node, additionalContent }: NodeMicrositeProps) {

    if (config.debug) {
        console.log('NodeMicrosite node');
        console.log(node);
    }

    const { t } = useTranslation('microsite');

    let html_content;
    if( node.body && node.body.value ){
        html_content = node.body.value;
    } else {
        html_content = additionalContent.micrositeHTML;
    }

    html_content = html_content.replace('[[webform]]', '<div id="form_replace"></div>');

    let webform_object = null;
    if( node.field_form ){
        webform_object = <Webform webform={node.field_form} />;
    }

    let html_content_parsed = null;
    if( webform_object ){
        html_content_parsed = parse(html_content, {
            replace: domNode => {
                // @ts-ignore
                if (domNode.hasOwnProperty('attribs') && domNode.attribs.id === 'form_replace') {
                    return webform_object;
                }
            }
        });
    } else {
        html_content_parsed = parse(html_content);
    }

    return (
        <>
        <div id={"microsite-content"}>{html_content_parsed}</div>
        </>
    )
}
