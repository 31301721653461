import React, { useEffect } from "react"
import Link from "next/link"
import { DrupalMenuLinkContent } from "next-drupal"
import { useTranslation } from "next-i18next";
import * as process from "process";
import Cookies from "js-cookie";

import { CookieConsent } from "../cookie-consent";


interface FooterProps {
    footer: DrupalMenuLinkContent[],
    social?: DrupalMenuLinkContent[],
    isHomepage?: boolean
}

export function Footer({ isHomepage, footer, social }: FooterProps) {
    /**
     * Cookie Consent functions - START
     */
    const [showCookieBar, setShowCookieBar] = React.useState<Boolean>(true)
    const { t } = useTranslation('common');
    const openCookieConsent = () => {
        setShowCookieBar( true);
    }
    const closeCookieConsent = () => {
        setShowCookieBar( false);
    }
    const cookie_names = {
        marketing: 'marketing-cookie',
        analytical: 'analytical-cookie'
    };
    useEffect(() => {
        for (const [cookie_type, cookie_name] of Object.entries(cookie_names)) {
            if(typeof Cookies.get(cookie_name) != 'undefined'){
                setShowCookieBar(false);
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    /**
     * Cookie Consent function - END
     */

    return (
        /* eslint-disable @next/next/no-img-element */
        <>
            <footer className={isHomepage ? 'homepage--footer' : ''}>
                <div className="container">
                    <div className="footer-inner">
                        <div className="footer-cookies-links">
                            {footer?.map((link) => {
                                if(link.url == '#cookie'){
                                    return <button key={link.id} id="cookies-bar--btn-open" onClick={(e) => openCookieConsent()}>
                                        {link.title}
                                    </button>
                                } else {
                                    return <Link href={link.url} passHref key={link.id}>
                                        {link.title}
                                    </Link>
                                }
                            })}
                        </div>
                        <div className="footer-awards">
                            <img src="/images/layout/effie2025.png" alt={t("Effie awards 2023")} />
                        </div>
                        <a href={process.env.NEXT_PUBLIC_BASE_URL} className="footer-global">www.ogilvy.com</a>
                        <ul className="footer-social">
                            {social?.map((link) => {
                                return <li key={link.id}>
                                    <Link href={link.url} passHref>
                                        {link.title}
                                    </Link>
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
            </footer>
            <CookieConsent closeFunction={closeCookieConsent} cookie_names={cookie_names} showCookieBar={showCookieBar}/>
        </>
        /* eslint-enable @next/next/no-img-element */
    )
}
