import { DrupalNode } from "next-drupal"
import React from "react";
import { processHTML } from "lib/utils";
import { Paragraphs } from "../paragraph/paragraphs";

export interface NodeBasicPageProps {
    node: DrupalNode
}

export function NodeBasicPage({ node, ...props }: NodeBasicPageProps) {
    return (
        <>
            <h1>{node.title}</h1>
            {node.body?.processed && (
                <div
                dangerouslySetInnerHTML={{ __html: processHTML(node.body?.processed) }}
                className="wysiwyg"
                />
            )}
            { node.field_paragraphs.length > 0 &&
                <Paragraphs paragraphs={node.field_paragraphs} key="paragraphs"/>
            }
        </>
    )
}
