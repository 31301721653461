import Link from "next/link"
import { DrupalNode } from "next-drupal"
import { formatDate } from "lib/utils"
import {useTranslation} from "next-i18next";
import config from "site.config";

interface NodeArticleTeaserProps {
    node: DrupalNode
}

export function NodeArticleOgilvyPerformanceTeaser({ node, ...props }: NodeArticleTeaserProps) {
    if( config.debug ) {
        console.log("NodeArticleOgilvyPerformanceTeaser")
        console.log(node)
    }

    const { t } = useTranslation('article');

    let link = node.path?.alias?node.path.alias:'/node/'+node.drupal_internal__nid;
    return (
        <Link href={link} className="news-box" {...props}>
            <div className="news-item-box">
                <span className="news-item-date">{formatDate(node.created)}</span>
                <h2 className="news-item-label">{node.title}</h2>
            </div>
            <div className="news-item-info">
                <p className="news-item-text">{node.field_short_perex}</p>
                <span className="news-item-btn">{t("article_detail")}</span>
            </div>
        </Link>
    )
}
