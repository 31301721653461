import React, {useEffect} from "react"
import Link from "next/link"
import {DrupalBlock, DrupalMenuLinkContent, JsonApiResource} from "next-drupal"
import * as process from "process";
import {CookieConsent} from "../cookie-consent";
import Cookies from "js-cookie";
import {useTranslation} from "next-i18next";
import {processHTML} from "../../lib/utils";
import classNames from "classnames";


interface FooterProps {
    leftmenu: DrupalMenuLinkContent[],
    rightmenu?: DrupalMenuLinkContent[],
    prefooter?: JsonApiResource[]
    isHomepage?: boolean
}

export function FooterOgilvyPerformance({ isHomepage, leftmenu, rightmenu, prefooter }: FooterProps) {
    /**
     * Cookie Consent functions - START
     */
    const [showCookieBar, setShowCookieBar] = React.useState<Boolean>(true)
    const { t } = useTranslation('common');
    const openCookieConsent = () => {
        setShowCookieBar( true);
    }
    const closeCookieConsent = () => {
        setShowCookieBar( false);
    }
    const cookie_names = {
        marketing: 'marketing-cookie',
        analytical: 'analytical-cookie'
    };
    useEffect(() => {
        for (const [cookie_type, cookie_name] of Object.entries(cookie_names)) {
            if(typeof Cookies.get(cookie_name) != 'undefined'){
                setShowCookieBar(false);
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    /**
     * Cookie Consent function - END
     */
    return (
        <>
            {prefooter?.map((block, index)=>{
                return (
                    <div className="ogperfo--prefooter" key={"prefooter-"+index}>
                        <div className="container">
                            <div className="divider"></div>
                            <div dangerouslySetInnerHTML={{ __html: processHTML(block.body?.processed) }} className={classNames("wysiwyg")} />
                        </div>
                    </div>
                );
            })}

            <footer className="ogperfo--footer">
                <div className="container">
                    <div className="footer-inner">
                        <div className="footer-cookies-links">
                            {leftmenu?.map((link) => {
                                if(link.url == '#cookie'){
                                    return <button key={link.id} id="cookies-bar--btn-open" onClick={(e) => openCookieConsent()}>
                                        {link.title}
                                    </button>
                                } else {
                                    return <Link href={link.url} passHref key={link.id}>
                                        {link.title}
                                    </Link>
                                }
                            })}
                        </div>
                        <ul className="footer-social">
                            {rightmenu?.map((link) => {
                                return <li key={link.id}>
                                    <Link href={link.url} passHref>
                                        {link.title}
                                    </Link>
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
            </footer>
            <CookieConsent closeFunction={closeCookieConsent} cookie_names={cookie_names} showCookieBar={showCookieBar}/>
        </>
    )
}
