import {
    GetServerSidePropsContext,
    GetServerSidePropsResult,
} from "next"
import { drupalInternal } from "lib/drupal"
import { DrupalNode } from "next-drupal"
import { promises as fs } from "fs";
import classNames from "classnames";
import process from "process";

import config from "site.config";
import { NodeArticle, NodeArticleProps } from "components/nodes/node--article"
import { NodeBasicPage } from "components/nodes/node--basic-page"
import { NodeStudie } from "components/nodes/node--studie";
import { NodeCampaign, NodeCampaignProps } from "components/nodes/node--campaign";
import { Layout, LayoutProps} from "components/layout/layout";
import {Meta} from "components/meta";
import {getGlobalElements} from "lib/get-global-elements";
import {LayoutStudie} from "components/layout/layout--studie";
import {LayoutMicrosite} from "components/layout/layout--microsite";
import {NodeMicrosite, NodeMicrositeProps} from "components/nodes/node--microsite";
import {LayoutTender} from "components/layout/layout--tender";
import {NodeTender} from "components/nodes/node--tender";
import {absolutePublicUrl} from "../lib/utils";
import Script from "next/script";
import {LayoutOgilvyPerformance} from "../components/ogilvy-performance/layout-ogilvy-performance";
import {NodeArticleOgilvyPerformance} from "../components/ogilvy-performance/node--article-ogilvy-performance";
import {NodePageOgilvyPerformancePage} from "../components/ogilvy-performance/node--page_ogilvy_performance";
import {NodeCampaignOgilvyPerformance} from "../components/ogilvy-performance/node--campaign-ogilvy-performance";
import { getParams } from "lib/get-params";
import Cookies from 'js-cookie';

const RESOURCE_TYPES = ["node--page", "node--page_ogilvy_performance", "node--article", "node--article_ogilvy_performance","node--studie","node--tendr","node--campaign","node--microsite"]

interface NodePageProps extends LayoutProps{
    node: DrupalNode,
    additionalContent: {}
}

export default function NodePage({ node, additionalContent, menus }:NodePageProps) {
    if (config.debug) {
        console.log('Nodepage node');
        console.log(node);
    }

    if (!node) return null
    const domain = Cookies.get('xdomain') ? Cookies.get('xdomain') : "default";

    switch(node.type){
        case "node--microsite":
            if( node.field_directory) {
                let scripts = [];
                let tags = node.metatag || [];
                {node.field_css_files?.length ? (
                    node.field_css_files.map((css_file, index) => {
                        tags.push({
                            tag: 'link',
                            attributes: {
                                rel: "stylesheet",
                                href: absolutePublicUrl(css_file?.uri?.url)
                            }
                        });
                    })
                ) : null}

                {node.field_css_external_files?.length ? (
                    node.field_css_external_files.map((css_file, index) => {
                        tags.push({
                            tag: 'link',
                            attributes: {
                                rel: "stylesheet",
                                href: css_file
                            }
                        });
                    })
                ) : null}

                {node.field_js_external_files?.length ? (
                    node.field_js_external_files.map((js_file, index) => {
                        scripts.push({
                            tag: 'Script',
                            attributes: {
                                src: js_file
                            }
                        });
                    })
                ) : null
                }

                if(node.field_js_script){
                    scripts.push({
                        tag: 'Script',
                        tag_content: node.field_js_script
                    });
                }

                tags.push({
                    tag: 'title',
                    attributes: {
                        name: 'title',
                        content: node.title
                    }
                });

                return (
                    <LayoutMicrosite>
                        <Meta title={node.title} tags={tags} scripts={scripts} path={node.path?.alias}/>
                        <NodeMicrosite node={node} additionalContent={additionalContent as NodeMicrositeProps["additionalContent"]} />
                    </LayoutMicrosite>
                )
            } else {
                return null;
            }

        case "node--studie":
            return (
                <LayoutStudie menus={menus}>
                    <Meta title={node.title} tags={node.metatag} path={node.path?.alias}/>
                    <NodeStudie node={node} />
                </LayoutStudie>
            )

        case "node--tendr":
            return (
                <LayoutTender menus={menus}>
                    <Meta title={node.title} tags={node.metatag} path={node.path?.alias}/>
                    <NodeTender node={node} />
                </LayoutTender>
            )

        case 'node--article_ogilvy_performance':
        case 'node--page_ogilvy_performance':
            return (
                <LayoutOgilvyPerformance menus={menus}>
                    <Meta title={node.title} tags={node.metatag} path={node.path?.alias} />
                    <div className={classNames("container", "container-"+node.type)}>
                        {node.type === "node--article_ogilvy_performance" && <NodeArticleOgilvyPerformance node={node} additionalContent={additionalContent as NodeArticleProps["additionalContent"]} />}
                        {node.type === "node--page_ogilvy_performance" && <NodePageOgilvyPerformancePage node={node} />}
                    </div>
                </LayoutOgilvyPerformance>
            )

        case "node--campaign":

            switch (domain){
                case "performance":
                    return (
                        <LayoutOgilvyPerformance menus={menus}>
                            <Meta title={node.title} tags={node.metatag} path={node.path?.alias} />
                            <div className={classNames("domain-"+domain, "container", "container-"+node.type)}>
                                <NodeCampaignOgilvyPerformance node={node} additionalContent={additionalContent as NodeCampaignProps["additionalContent"]} />
                            </div>
                        </LayoutOgilvyPerformance>
                    )
                default:
                    return (
                        <Layout menus={menus}>
                            <Meta title={node.title} tags={node.metatag} path={node.path?.alias} />
                            <div className={classNames("domain-"+domain, "container", "container-"+node.type)}>
                                <NodeCampaign node={node} additionalContent={additionalContent as NodeCampaignProps["additionalContent"]} />
                            </div>
                        </Layout>
                    )
            }

        default:
            return (
                <Layout menus={menus}>
                    <Meta title={node.title} tags={node.metatag} path={node.path?.alias} />
                    <div className={classNames("container", "container-" + node.type)}>
                        {node.type === "node--page" && <NodeBasicPage node={node} />}
                        {node.type === "node--article" && <NodeArticle node={node} additionalContent={additionalContent as NodeArticleProps["additionalContent"]} />}
                        {node.type === "node--campaign" && <NodeCampaign node={node} additionalContent={additionalContent as NodeCampaignProps["additionalContent"]} />}
                    </div>
                </Layout>
            )
    }
}

export async function getServerSideProps(
    context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<NodePageProps>> {
    const path = await drupalInternal.translatePathFromContext(context)

    if (!path || !RESOURCE_TYPES.includes(path.jsonapi.resourceName)) {
        return {
            notFound: true,
        }
    }

    const type = path.jsonapi.resourceName
    const node = await drupalInternal.getResourceFromContext<DrupalNode>(path, context, {
        params: getParams(type),
    })

    if (!node || (!context.preview && node?.status === false)) {
        return {
            notFound: true,
        }
    }

    let additionalContent: NodePageProps["additionalContent"] = {}

    if (node.type === "node--article") {
        // Fetch featured articles.
        additionalContent["featuredArticles"] =
            await drupalInternal.getResourceCollectionFromContext("node--article", context, {
                params: getParams("node--article", "teaser", false)
                    .addFilter("id", node.id, "<>")
                    .addFilter("field_color.id", node.field_color.id)
                    .addPageLimit(5)
                    .addSort("created", "DESC")
                    .getQueryObject(),
            })
    }

    if(node.type === "node--microsite") {
        if( node.field_directory ){
            const file = process.cwd() + '/public/microsites/' + node.field_directory + '/index.html';
            try {
                additionalContent["micrositeHTML"] = await fs.readFile(file, 'utf8');
            } catch (error) {
                console.error('File loading error', error);
            }
        } else {
            additionalContent["micrositeHTML"] = '';
        }
    }

    if (node.type === "node--campaign") {
        // Fetch featured campaigns.
        let expertize_id = node.field_expertize[0]?.id;
        additionalContent["featuredCampaigns"] =
            await drupalInternal.getResourceCollectionFromContext("node--campaign", context, {
                params: getParams("node--campaign", "teaser", false)
                    .addFilter("id", node.id, "<>")
                    .addFilter("field_expertize.id", expertize_id)
                    .addPageLimit(3)
                    .addSort("created", "DESC")
                    .getQueryObject(),
            })
    }

    if (node.type === "node--article_ogilvy_performance") {
        // Fetch featured articles.

        additionalContent["featuredArticles"] =
            await drupalInternal.getResourceCollectionFromContext("node--article_ogilvy_performance", context, {
                params: getParams("node--article_ogilvy_performance", "teaser", false)
                    .addFilter("id", node.id, "<>")
                    .addPageLimit(5)
                    .addSort("created", "DESC")
                    .getQueryObject(),

            })
    }

    if (node.type === "node--article_ogilvy_performance") {
        // Fetch featured articles.

        additionalContent["featuredArticles"] =
            await drupalInternal.getResourceCollectionFromContext("node--article_ogilvy_performance", context, {
                params: getParams("node--article_ogilvy_performance", "teaser", false)
                    .addFilter("id", node.id, "<>")
                    .addPageLimit(5)
                    .addSort("created", "DESC")
                    .getQueryObject(),

            })
    }

    return {
        props: {
            ...(await getGlobalElements(context, node.type)),
            node,
            additionalContent
        },
    }
}
