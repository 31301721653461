import * as React from "react"
import {DrupalBlock, DrupalMenuLinkContent, JsonApiResource} from "next-drupal"
import { Footer } from "components/layout/footer"
import { Suspense } from "react";
import Loading from "../../pages/Loading";
import { useRouter } from "next/router";
import {NavbarOgilvyPerformance} from "./navbar-ogilvy-performance";
import {FooterOgilvyPerformance} from "./footer-ogilvy-performance";
import {LayoutProps} from "../layout/layout";

export function LayoutOgilvyPerformance({ menus, children }: LayoutProps) {
    const router = useRouter();

        return (
            <>
                <NavbarOgilvyPerformance links={menus.performancemain} headerlinks={menus.performancesocial} pathName={router.pathname}/>
                <main className="ogperfo--main">
                    <Suspense fallback={<Loading />}>{children}</Suspense>
                </main>
                <FooterOgilvyPerformance isHomepage={true} leftmenu={menus.performancefooter} rightmenu={menus.footerstudie} prefooter={menus.blocks} />
            </>
        )
}
