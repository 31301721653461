import React, { useEffect, useRef, useState } from "react"
import { useRouter } from "next/router"
import { DrupalMenuLinkContent } from "next-drupal"
import classNames from "classnames"
import { LocaleSwitcher } from "components/layout/locale-switcher"
import Link from "next/link"
import {Menu} from "components/layout/menu"
import {useTranslation} from "next-i18next";
import {isActiveLink} from "../../lib/utils";

interface NavbarProps {
    headerlinks: DrupalMenuLinkContent[],
    links: DrupalMenuLinkContent[],
    pathName: string
}

export function NavbarOgilvyPerformance({ headerlinks, links, pathName, ...props }: NavbarProps) {
    const { locale, asPath, pathname } = useRouter();
    const { t } = useTranslation('navbar');
    const [showMenu, setShowMenu] = React.useState<Boolean>(false);
    const categoryMenuRef = useRef(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            if (entries[0]) {
                const { height } = entries[0].target.getBoundingClientRect();
                setHeight(height);
            }
        });

        const element = categoryMenuRef.current;
        if (element) {
            resizeObserver.observe(element);
        }

        // Cleanup on unmount
        return () => {
            if (element) {
                resizeObserver.unobserve(element);
            }
        };
    }, []);

    let path_menu = '/'+locale+(asPath=='/'?'':asPath);
    let path_menu_without_locale = (asPath=='/'?'':asPath);

    return (
        <header className={classNames("ogperfo--header", {"mob-menu-visible": showMenu})}>
            <div className="container">
                <nav>
                    <div className={classNames("nav-toggle", {"active": showMenu})} onClick={() => setShowMenu(!showMenu)}>
                        <span></span>
                        <span></span>
                    </div>
                    <>
                        <Link href="/" title="Ogilvy Performance Marketing" className="header-logo">Ogilvy Performance Marketing</Link>

                        {headerlinks ?
                            headerlinks.map((link, index) => (
                            <Link href={link.url} passHref key={"menu-link-"+index} className={classNames(
                                    "link",
                                    isActiveLink(link.url, locale, path_menu_without_locale) ? "active" : ""
                                )}>
                                {link.title}
                            </Link>
                            ))
                        : null }
                    </>
                </nav>
                {links ?
                    <div className="category-menu--wrapper" style={{ height: `${height}px` }}>
                        <ul ref={categoryMenuRef} className="category-menu">
                            {links.map((link, index) => (
                                <li key={"menu-link-"+index} >
                                    <Link href={link.url} passHref className={classNames(
                                        "link",
                                        isActiveLink(link.url, locale, path_menu_without_locale) ? "active" : ""
                                    )}>
                                        {link.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    : null }

            </div>
        </header>
    )
}
