import Image from "next/image"
import { DrupalNode } from "next-drupal"
import {absolutePublicUrl, processHTML} from "lib/utils"
import ReactPlayer from "react-player"
import "video-react/dist/video-react.css";
import {NodeCampaignTeaser} from "../nodes/node--campaign--teaser";
import {NodeCampaignProps} from "../nodes/node--campaign";
import {useTranslation} from "next-i18next";
import config from "site.config"
import {useRouter} from "next/router";
import {Paragraphs} from "../paragraph/paragraphs";
import React from "react";



export function NodeCampaignOgilvyPerformance({ node, additionalContent, ...props }: NodeCampaignProps) {
    if( config.debug) {
        console.log('NodeCampaignOgilvyPerformance');
        console.log(node);
    }

    const router = useRouter();
    const { t } = useTranslation('campaign');
    return (
        <>
            <div className="campaign-header">
                <a className="btn btn--back tst" href={router?.locale == 'en'?'/en':'/'} onClick={(e) => {
                    localStorage.setItem("get_back", "true");
                }}>{t("Naše práce")}</a>
                <h1>{node.title}</h1>
            </div>
            <div className="campaign-content">
                <div className="campaign-content-left">
                {node.field_image?.uri.url && (
                    <Image
                        src={absolutePublicUrl(node.field_image.uri.url)}
                        width={480}
                        height={300}
                        layout="intrinsic"
                        objectFit="cover"
                        className="rounded-lg" alt={node.field_image.resourceIdObjMeta.alt} />
                )}
                </div>
                <div className="campaign-content-right">
                {node.body?.processed && (
                    <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: processHTML(node.body?.processed) }} />
                )}
                </div>

                {node.field_video?.length > 0 && (
                    <>
                        {node.field_video.map((video, index) => (
                            <div className="campaign-video" key={"campaign-video"+index}>
                                <ReactPlayer
                                    url={video.field_media_oembed_video}
                                    key={video.id}
                                    controls={true}
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        ))}
                    </>
                )}

                { node.field_paragraphs.length > 0 &&
                    <Paragraphs paragraphs={node.field_paragraphs} key="paragraphs"/>
                }
            </div>
            {additionalContent?.featuredCampaigns && (
                <div className="campaign-more">
                    <h3>{t("Další práce")}</h3>
                    <ul className="campaign-more-list">
                        {additionalContent.featuredCampaigns.map((node) => (
                            <NodeCampaignTeaser key={node.id} node={node} />
                        ))}
                    </ul>
                </div>
            )}
        </>
    )
}
